.modalOverlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    background: #fff;
    width: 100%;
    max-width: 400px;
    height: 90%;
    border-radius: 16px 16px 0 0;
    padding: 20px;
    position: relative;
    animation: slideUp 0.3s ease-out;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);

    .modalContent {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .text {
        color: #9396a6;
      }

      h3 {
        font-size: 22px;
        font-weight: bold;
        text-align: center;
      }

      span {
        color: #000;
        font-weight: 600;
      }

      .disclaimer {
        font-size: 13px;
        text-align: left;
        color: #9396a6;
        font-weight: 500;
      }

      .offerText {
        background: #f8f8f8;
        border-radius: 12px;
        padding: 12px;
        font-size: 16px;
      }
    }
  }
}
