.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h2 {
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .input {
    margin-top: 24px;
    margin-bottom: 16px;
    .hint {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: #0000007a;
      span {
        color: #000;
      }
    }
    h5 {
      font-size: 20px;
    }
  }
  .usInputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--card-bg);
    border-radius: 16px;
  }
  .action {
    .policy {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;
      .policyText {
        line-height: 18px;
        font-size: 14px;
        color: #0000007d;
        font-weight: 500;
        a {
          color: var(--primary);
        }
      }
    }
  }
}
