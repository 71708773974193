.container {
  padding: 10px 8px 0 8px;
  margin-bottom: 20px;

  .transform {
    background-color: #f8f8f8;
    background-image: url("../transform//assets/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
    padding: 10px 8px 0 8px;
    margin: 0 auto;
  }

  .header {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-radius: 8px;
    margin-bottom: 20px;

    .headerItem {
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      width: 50%;
      cursor: pointer;

      span {
        font-weight: 600;
        color: #7c8af7;
      }
    }

    .headerDivider {
      height: 22px;
      width: 1px;
      background-color: #d9d9d9;
    }
  }

  .body {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .imageWrapper {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: end;
      z-index: 1;

      img {
        width: 166px;
        height: 100%;
      }

      .comparisonImage {
        z-index: 2;
      }
    }
  }

  .cards {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin-top: 30px;
    gap: 20px;
    .cardsWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .metric {
      background-color: #f7f7f7;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      padding: 10px;
      justify-content: space-between;
      gap: 8px;
    }
    .title {
      color: #a1a1a1;
      font-weight: 500;
      font-size: 12px;
    }

    .value {
      font-size: 15px;
      font-weight: 500;
      line-height: 19.5px;
      color: #000;
    }

    .levelIndicators {
      display: flex;
      justify-content: left;
      gap: 5px;
    }

    .indicator {
      width: 40px;
      height: 8px;
      background-color: #7c8af7;
      opacity: 0.2;
      border-radius: 13px;
    }

    .indicator.filled {
      opacity: 1;
    }
  }

  .divider {
    width: 1px;
    height: 119px;
    background-color: #e0e0e0;
  }
}
