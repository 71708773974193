.inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cardInput {
  padding: 12px;
  border: 1px solid #dfe1e5;
  border-radius: 8px;
  background-color: #fff;
}

.splitFields {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.expiryDate,
.cvv {
  flex: 1;
}

.inputField {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #dfe1e5;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cardInput,
.inputField {
  animation: fadeIn 0.8s ease-in-out;
}

.inputField::placeholder {
  color: #aab7c4;
}
