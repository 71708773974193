.modalOverlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  overflow-y: auto;
  background: #fff;
  width: 100%;
  max-width: 400px;
  height: 90%;
  border-radius: 16px 16px 0 0;
  padding: 20px;
  position: relative;
  text-align: left;
  animation: slideUp 0.3s ease-out;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);

  h2 {
    margin-bottom: 30px;
    text-align: center;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 34px;
  background: none;
  border: none;
  color: #9396a6;
  cursor: pointer;
}

.planDetails {
  display: flex;
  justify-content: space-between;

  p {
    margin: 5px 0;
    font-size: 14px;
    color: #9396a6;
    font-weight: 500;

    span {
      color: #f91e1e;
    }
  }
}

.paymentSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e9f7eb;
  padding: 15px;
  border-radius: 8px;
  font-weight: bold;

  span {
    color: #25b67d;
  }

  margin: 10px 0;
  text-align: center;
}

.totalAmount {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin: 5px 0;
  text-align: center;
  p {
    font-size: 18px;
    span {
      color: #9396a6;
      font-weight: 500;
    }
  }
}

.discountText {
  text-align: right;
  color: #f91e1e;
  font-weight: 600;
}

.paymentMethods {
  display: flex;
  flex-direction: column;
  gap: 12px;

  label {
    display: flex;
    align-self: self-start;
    gap: 10px;
    align-items: center;
  }
}

.paymentMethod {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  padding: 12px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  label {
    display: flex;
    align-self: self-start;
    gap: 12px;
    align-items: center;
    width: 100%;
    cursor: pointer;

    input[type="radio"] {
      appearance: none;
      width: 20px;
      height: 20px;
      border: 2px solid #E4E6E7;
      border-radius: 50%;
      margin: 0;
      position: relative;
      cursor: pointer;

      &:checked {
        border-color: var(--main-pink);
        
        &:after {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          background: var(--main-pink);
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .cardContainer {
    width: 100%;
  }

}

.applePayButton {
  background-color: black;
  color: white;
  padding: 14px 0;
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  margin: 5px 0;
  cursor: pointer;
}

.applePayButton button {
  font-size: 18px;
  font-weight: bold;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.applePayDescription {
  font-size: 12px;
  color: #555;
  margin-top: 4px;
  text-align: center;
}

.section {
  padding: 8px 0;
  border-bottom: 1px solid #ECECEC;
}

.paymentTitle {
  text-align: center;
  margin: 18px 0 12px 0;
  font-size: 20px !important;
  font-weight: 700;
  letter-spacing: -0.41px;
}

.paymentContent {
  width: 100%;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #ECECEC;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  p {
    font-size: 14px;
    margin-top: 4px;
  }
}


