.getPlanButton {
  height: 60px;
}
.skipButton {
  width: 100%;
  padding: 15px;
  background-color: transparent;
  color: #9396a6;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.25px;
  text-align: center;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
}
