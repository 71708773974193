.featuresList {
  margin: 24px 0;
  list-style: none;
  padding: 0;

  li {
    font-size: 14px;
    color: #9396a6;
    font-weight: 500;
    margin-bottom: 12px;
    span {
      margin-right: 8px;
      color: var(--main-pink);
      font-size: 16px;
    }
  }
}
