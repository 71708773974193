.goals {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 18px;

  .goalsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
  }

  .goalItem {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: #f0f0f0;
    padding: 10px 10px 10px 8px;
    width: 100%;
    gap: 10px;

    .icon {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
    .text {
      display: flex;
      flex-direction: column;
      flex: 1;
      p {
        font-size: 12px;
        color: #000;
        opacity: 0.5;
        font-weight: 500;
      }
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .divider {
    width: 1px;
    height: 119px;
    background-color: #e0e0e0;
  }
}
