.card {
  background-color: #f1f2f3;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  outline: 0;
  border: 0;
  border-radius: 16px;
  color: #000;
  transition: .6s ease;
  &.fullWidth {
    width: 100%;
  }
  &.active {
    background-color: var(--primary-hover);
  }
  &:active {
    transform: scale(1.1);
  }
  .icon {
    background-color: white;
    height: 40px;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    img {
      width: 28px;
      height: 28px;
    }
  }
  .text {
    font-size: 14px;
    font-weight: 600;

    .paragraph {
      font-size: 13px;
      color: #000;
      opacity: 0.5;
    }
  }
  &:active {
    background-color: var(--primary-hover);
    .icon {
      background-color: #fff;
    }
  }
}

@media screen and (min-width: 430px) {
  .card {
    .text {
      font-size: 16px;
    }
  }
}
