.container {
  background-image: url(./img/bg.jpg);
  background-size: cover;
  background-position: center center;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;
  .action {
    padding-top: 16px;
  }
}
