.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h2 {
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .switch {
    margin: 0 auto;
    margin-top: 24px;
    width: 171px;
    border-radius: 8px;
    background-color: var(--card-bg);
    padding: 1px;
    display: flex;
    .item {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 1.4rem;
      &.active {
        box-shadow: 0px 3px 1px 0px #0000000a;

        box-shadow: 0px 3px 8px 0px #0000001f;

        border: 0.5px solid #0000000a;
        background-color: #fff;
        border-radius: 6px;
      }
    }
  }
  .input {
    margin-top: 24px;
    margin-bottom: 16px;
    .hint {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: #0000007a;
      span {
        color: #000;
      }
    }
    h5 {
      font-size: 20px;
    }
  }
  .usInputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--card-bg);
    border-radius: 16px;
  }
  .action {
    .policy {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;
      .policyText {
        line-height: 18px;
        font-size: 14px;
        color: #0000007d;
        font-weight: 500;
        a {
          color: var(--primary);
        }
      }
    }
  }
}
