.card {
  background-color: #f1f2f3;
  outline: 0;
  border: 0;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  justify-content: space-between;
  transition: 0.6s ease;
  color: #000;
  &:active {
    transform: scale(1.1);
  }
  &.fullWidth {
    width: 100%;
  }
  &.checked {
    background-color: var(--primary-hover);
    .content {
      .icon {
        background-color: #fff;
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    .icon {
      background-color: white;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      img {
        height: 28px;
        width: 28px;
      }
    }
    .text {
      font-size: 14px;
      font-weight: 500;
      font-weight: 600;

      .paragraph {
        font-size: 13px;
        color: #000;
        opacity: 0.5;
      }
    }
  }
}

@media screen and (min-width: 430px) {
  .card {
    .content {
      .text {
        font-size: 16px;
      }
    }
  }
}
