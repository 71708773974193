.profileCard {
  h2 {
    text-align: center;
  }
  .wrapper {
    background: linear-gradient(
      180deg,
      #7c8af7 0%,
      rgba(137, 150, 253, 0.49) 46%,
      rgba(210, 238, 214, 0) 100%
    );
    .profileChart {
      margin-top: 32px;
      margin: 0 auto;
      padding: 8px;
      display: flex;
      width: 375px;
      height: 420px;
      background: url("./img/img.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .bodyImg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .clouds {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding-bottom: 10px;
        overflow: hidden;
        .cloud {
          background-color: white;
          text-align: center;
          border-radius: 12px;
          width: 114px;
          padding: 10px 12px;
          p {
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
            font-weight: 500;
          }
          h5 {
            margin: 0;
            font-size: 15px;
            line-height: 19.5px;
          }
        }
      }

      .goal {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 10px;

        .goalCloud {
          background-color: white;
          border-radius: 12px;
          padding: 9px;
          width: 90%;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          box-shadow: 0px 1px 12px 0px #00000014;
        }
      }
    }
  }
}
